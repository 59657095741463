<template>
  <div v-show="loaded">
    <t2-header :image="headerImage" />
    <router-view @open-polar-modal="openPolarModal" />
    <t2-polar-modal :pole="polarPopupData">
      <div class="popup--wrapper">
        <t2-heading level="2" class="polar--heading">
          {{ polarPopupData.title }}
        </t2-heading>
        <p class="polar--sub-heading" v-html="polarPopupData.description" />
        <div class="popup--actions">
          <t2-button
            class="margin-auto"
            @click="emitter.emit('close-modal', { id: polarPopupData.id })"
          >
            {{ polarPopupData.closeButton }}
          </t2-button>
        </div>
      </div>
    </t2-polar-modal>
    <section>
      <t2-footer-copyright :copyright="copyright" />
    </section>
    <t2-cookie-agreement
      v-if="cookieAgreement.show"
      :data="cookieAgreement.data"
      @button-click="handleCookieAgreementClick"
    />
  </div>
</template>
<script>
import T2Button from "@atoms/Button/Button.vue";
import T2Heading from "@atoms/Heading/Heading.vue";
import T2FooterCopyright from "@atoms/FooterCopyright/FooterCopyright.vue";
import T2Header from "@organisms/Narsyk/Header/Header.vue";
import T2PolarModal from "@organisms/PolarModal/PolarModal.vue";
import T2CookieAgreement from "@organisms/CookieAgreement/CookieAgreement.vue";
import LoaderService from "@/services/loader.js";
import MetadataService from "@/services/metadata.js";
import CookieAgreementService from "@/services/cookieAgreement.js";
import constants from "@/constants/index.js";

export default {
  components: {
    T2Button,
    T2Heading,
    T2FooterCopyright,
    T2Header,
    T2CookieAgreement,
    T2PolarModal,
  },
  inject: ["emitter"],
  data() {
    return {
      loaded: false,
      headerImage: constants.Images.Logo.Url,
      polarPopupData: {
        id: null,
        title: null,
        description: null,
        closeButton: null,
      },
      copyright: "",
      cookieAgreement: {
        data: null,
        show: false,
      },
    };
  },
  mounted() {
    this.emitter.on("resources-loaded", this.handleInitialLoad);
  },
  beforeUnmount() {
    this.emitter.off("resources-loaded", this.handleInitialLoad);
  },
  methods: {
    handleInitialLoad() {
      LoaderService.hideLoader().then(() => {
        this.loaded = true;
      });

      const metaData = MetadataService.formatMetadata({
        title: constants.Resources.Narsyk.MetaTitle,
        content: constants.Resources.Narsyk.MetaDescription,
      });
      MetadataService.setMetadata(metaData);
      this.copyright = constants.Resources.Footer.Copyright;

      if (window.cookieBotFailed) {
        setTimeout(this.showCookieAgreement, 1500);
      }
    },
    openPolarModal(data) {
      if (
        data.id === constants.PolarPopupType.Success ||
        data.id === constants.PolarPopupType.Error
      ) {
        this.polarPopupData.id = data.id;
        this.polarPopupData.title = data.title;
        this.polarPopupData.description = data.description;
        this.polarPopupData.closeButton = data.closeButton;
      }

      this.$nextTick(() => {
        this.emitter.emit(constants.ModalControl.Open, this.polarPopupData);
      });
    },
    showCookieAgreement() {
      this.cookieAgreement.data = CookieAgreementService.formatCookieAgreementData();
      this.cookieAgreement.show = CookieAgreementService.showCookieAgreement();
    },
    handleCookieAgreementClick() {
      CookieAgreementService.handleCookieAgreementClick(this);
    },
  },
};
</script>
