<template>
  <component :is="tag" v-bind="$attrs" class="v-button" :class="classes">
    <slot />
  </component>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    pink: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      default: false,
    },
    gradientBlue: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return {
        "v-button---disabled": this.$attrs.disabled,
        "v-button--link": this.$attrs.href && !this.default,
        "v-button---pink": this.pink,
        "v-button---gradient-blue": this.gradientBlue,
        "v-button---blue": this.blue,
        "v-button--secondary": this.secondary,
        "v-button---link": this.link,
        "v-button---white": this.white,
        "v-button---text": this.text,
      };
    },
    tag() {
      return this.$attrs.href ? "a" : "button";
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";

.v-button {
  .BP-bold;
  cursor: pointer;
  display: inline-block;
  padding: 12px 30px;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  min-width: 180px;
  color: @color-white;
  fill: @color-white;
  border-radius: 10px;
  border: solid 2px transparent;
  background: @color-red;
  transition: 400ms ease-in-out;
  text-decoration: none;
  letter-spacing: 1px;

  @media @tablet {
    font-size: 14px;
    padding: 12px;
    min-width: 140px;
  }

  &:hover {
    background: @color-darkred;
    transition: 200ms ease-in-out;
  }

  & > p {
    margin: 0;
  }

  & > p img {
    transform: translateY(3px);
    max-width: 24px;
    margin-left: 5px;
  }

  &--secondary {
    border: 2px solid @color-red;
    border-radius: 10px;
    background: transparent;
    color: @color-red;
    fill: @color-red;
    &:hover {
      color: @color-white;
      fill: @color-white;
      background: @color-red;
    }
  }
  &--secondary.v-button---blue {
    background: transparent;
    border-color: @color-blue;
    color: @color-blue;
    fill: @color-blue;
    &:hover {
      color: @color-white;
      fill: @color-white;
      background: @color-blue;
    }
  }
  &--secondary.v-button---white {
    background: transparent;
    border-color: @color-white;
    color: @color-white;
    fill: @color-white;
    &:hover {
      color: @color-white;
      fill: @color-white;
      background: @color-red;
      border-color: @color-red;
    }
  }
  &--secondary.v-button---pink {
    background: transparent;
    &.loading::before {
      background: @color-darkred;
      border-color: @color-darkred;
    }
    &:hover {
      background: @color-darkred;
      border-color: @color-darkred;
    }
  }
  &--link {
    border-radius: 4px;
    background: transparent;
    color: @color-blue;
    fill: @color-blue;
    text-decoration: none;
    &:hover {
      color: @color-darkblue;
      fill: @color-darkblue;
      background: transparent;
    }
  }
  &--link.v-button--secondary {
    border-radius: 10px;
    &:focus {
      color: @color-blue;
    }
    &:hover:focus {
      color: @color-white;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
  &.loading {
    position: relative;
    pointer-events: none;
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: @color-red;
      content: "";
      border-radius: 8px;
    }
    .loading-circle {
      width: 26px;
      height: 26px;
    }
    .loading-circle .circle:before {
      background: @color-white;
    }
  }
  &---pink {
    background: @gradient-red-pink;
    &.loading::before {
      background: @gradient-red-pink;
    }
    &:hover {
      background: linear-gradient(90deg, #971b45 0%, #971b45 100%);
    }
  }
  &---gradient-blue {
    background: linear-gradient(90deg, #0a4e9b 0%, #009fdb 100%);
    border: 0;
    padding: 14px 30px;
    &.loading::before {
      background: linear-gradient(90deg, #0a4e9b 0%, #009fdb 100%);
    }
    &:hover {
      background: linear-gradient(90deg, #0a4e9b 0%, #0a4e9b 100%);
    }
  }
  &---blue {
    background: @color-blue;
    &.loading::before {
      background: @color-blue;
    }
    &:hover {
      background: @color-darkblue;
    }
  }
  &---link {
    min-width: 1px;
    border-radius: 0;
    background: transparent;
    color: @color-blue;
    fill: @color-blue;
    padding: 0 0 12px;
    border: 0;
    border-bottom: 2px solid @color-blue;
    &:hover {
      background: transparent;
      color: @color-darkblue;
      fill: @color-darkblue;
      border-color: @color-darkblue;
    }
  }
  &---text {
    min-width: 1px;
    border-radius: 0;
    background: transparent;
    color: @color-blue;
    fill: @color-blue;
    padding: 0;
    letter-spacing: normal;
    &:hover {
      background: transparent;
      color: @color-darkblue;
      fill: @color-darkblue;
    }
  }
  &---disabled {
    background: @color-grey-6;
    pointer-events: none;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &.loading {
    position: relative;
    pointer-events: none;
  }
  &.loading:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: @color-red;
    content: "";
    border-radius: 8px;
  }
  &.loading .loading-circle {
    width: 26px;
    height: 26px;
  }
  &.loading .loading-circle .circle:before {
    background: @color-white;
  }
}
a.v-button {
  &:hover,
  &:focus {
    color: @color-white;
    fill: @color-white;
  }
}
a.v-button--secondary {
  &:focus {
    color: @color-blue;
    fill: @color-blue;
  }
}
.v-button.v-button--secondary.v-button---disabled {
  border: 2px solid @color-grey-6;
  color:@color-white;
  fill:@color-white;

  &:hover {
    color: @color-white;
    fill: @color-white;
    background: @color-grey-6;
  }
}
</style>
