export default {
  PricePlansCancellation: "PricePlansCancellation",
  PortOut: "PortOut",
  NewReport: "NewReport",
  NewSettings: "NewSettings",
  NewMyRequests: "NewMyRequests",
  NewDashboard: "NewDashboard",
  ValidationMessages: "ValidationMessages",
  Footer: "Footer",
  Campaign: "Campaign",
  NewPolarModal: "NewPolarModal",
  NewInvoice: "NewInvoice",
  NewRefill: "NewRefill",
  NewHelp: "NewHelp",
  CardSettings: "CardSettings",
  NewPricePlans: "NewPricePlans",
  Cookies: "Cookies",
  NewRegistration: "NewRegistration",
  NewRegistrationLanding: "NewRegistrationLanding",
  Consents: "Consents",
  Login: "Login",
  PasswordRecovery: "PasswordRecovery",
  Refill: "Refill",
  RefillCampaign: "FonosCampaign",
  Metadata: "Metadata",
  NotFound: "PageNotFound",
  AwardsCampaign: "AwardsCampaign",
  AwardsCampaignMedals: "AwardsCampaignMedals",
  AwardsCampaignLanding: "AwardsCampaignLanding",
  AwardsCampaignFaq: "AwardsCampaignFaq",
  Exceptions: "Exceptions",
  SimRefillCampaign: "RefillCampaign",
  CoverageMap: "CoverageMap",
  ConsentCampaign: "ConsentCampaign",
  Narsyk: "Narsyk",
  NewExceptions: "NewExceptions",
  ChristmasCampaign: "ChristmasCampaign",
  IsicCampaign: "IsicCampaign",
  AlienwareCampaign: "AlienwareCampaign",
  Whalebone: "Whalebone",
  AirPodsCampaign: "AirPodsCampaign",
  SmsChallenge: "SmsChallenge",
  DeviceInformation: "DeviceInformation",
  ESim: "ESim",
  ComboCampaign: "ComboCampaign",
  SummerCampaign2023: "SummerCampaign2023",
  WhyPildyk: "WhyPildyk",
  PrizeCampaign: "PrizeCampaign",
};
