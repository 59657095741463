<template>
  <svg
    v-if="iconId"
    :key="iconId"
    class="svg"
  >
    <use :xlink:href="'/img/icons.svg?v=40#' + iconId" />
  </svg>
</template>
<script>
export default {
  props: {
    iconId: {
      type: String,
      required: true,
    }
  },
};
</script>
