<template>
  <div
    class="v-input"
    :class="[
      {
        'v-input---blue': focus,
        'v-input---pink': error,
        'v-input---with-validation': withValidation
      },
      $attrs.class,
    ]"
  >
    <label v-if="label" class="v-input--label">
      <span v-html="label" />
      <span v-if="tooltip" class="v-input--tooltip">
        <t2-tooltip :text="tooltip">
          <t2-svg icon-id="info" />
        </t2-tooltip>
      </span>
    </label>
    <div
      class="v-input--container"
      :class="{
        'v-input---has-error': error,
        'v-input--disabled': disabled,
      }"
    >
      <div v-if="iconId" class="v-input--icon-container">
        <t2-svg :icon-id="iconId" class="v-input--icon" />
      </div>
      <div v-if="prefix" class="v-input--prefix">{{ prefix }}</div>
      <input
        ref="input"
        v-validation="validation"
        :type="type"
        :placeholder="$attrs.placeholder"
        :minlength="$attrs.minlength"
        :maxlength="maxLength"
        :value="modelValue"
        :name="$attrs.name"
        :autocomplete="$attrs.autocomplete"
        :inputmode="$attrs.inputmode"
        :pattern="$attrs.pattern"
        :disabled="disabled"
        class="v-input--field"
        :class="{
          'input v-input--field---number-prefixed': prefix,
          'v-input--field---password': isPassword
        }"
        v-bind="typeAttributes"
        @input="handleInput"
        @focus="focus = true"
        @blur="handleBlur"
        @keyup.enter="$emit('enter', { value: $event.target.value, errors })"
        @keyup.exact="blurTest"
        @keyup="$emit('keyup', $event)"
      />
      <div
        v-if="isPassword"
        class="v-input--password-icon-container"
        @click="togglePassword"
      >
        <t2-svg
          :icon-id="passwordIcon"
          class="v-input--password-icon"
        />
      </div>
      <slot name="inside" />
    </div>
    <slot />
    <t2-input-error v-if="error" :message="error" />
  </div>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
import T2InputError from "@molecules/InputError/InputError.vue";
import T2Tooltip from "@atoms/Tooltip/Tooltip.vue";
export default {
  components: {
    T2Svg,
    T2InputError,
    T2Tooltip,
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    iconId: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    blur: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    validation: {
      type: Object,
      default: () => {},
    },
    tooltip: {
      type: String,
      default: "",
    },
    withValidation: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    addSpaceAfterChars: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "enter",
    "keyup",
    "blur",
    "update:modelValue"
  ],
  data() {
    return {
      focus: false,
      error: null,
      showPassword: false,
      isPassword: this.$attrs.type === "password",
    };
  },
  computed: {
    type() {
      if (this.isPassword && this.showPassword) {
        return "text";
      }

      if (this.$attrs?.type === "code" && this.addSpaceAfterChars) {
        return "text";
      }

      if (this.$attrs?.type === "code") {
        return "number";
      }

      if (this.$attrs.type) {
        return this.$attrs.type;
      }

      return "text";
    },
    typeAttributes() {
      const numerics = ["code", "number"];
      if (numerics.includes(this.type) || (this.$attrs?.type === "code" && this.addSpaceAfterChars)) {
        return {
          inputmode: "numeric",
          pattern: "[0-9]*",
        };
      }

      return {};
    },
    input() {
      return this.$el.querySelector("input");
    },
    maxLength() {
      const { maxlength, type } = this.$attrs
      if (maxlength) {
        return parseInt(maxlength) + this._formatAdditionalLength(maxlength);
      }

      const types = {
        code: 8,
        password: 128
      };

      if (this.isPassword && this.showPassword) {
        return types.password;
      }

      return types[type] ? types[type] + this._formatAdditionalLength(types[type]) : null;
    },
    passwordIcon() {
      return this.showPassword ? "icon-unhidden" : "icon-hidden";
    }
  },
  watch: {
    errorMessage(val) {
      this.error = val;
    },
  },
  mounted() {
    if (this.errorMessage) {
      this.error = this.errorMessage;
    }
  },
  methods: {
    blurTest(event) {
      if (this.blur && this.blur.indexOf(event.keyCode) !== -1) {
        this.input.blur();
        this.focus = false;
        this.$emit("blur", { value: event.target.value, errors: this.errors });
      }
    },
    handleBlur() {
      this.focus = false;
      this.error = null;
    },
    handleInput(event) {
      if (
        this.maxLength &&
        this.input.value &&
        this.input.value.length > this.maxLength
      ) {
        this.input.value = this.input.value.slice(0, this.maxLength);
      }

      const isCodeInput = this.$attrs.type === "code" && event.data;

      if (isCodeInput && this.addSpaceAfterChars) {
        this.input.value = this.input.value
          .replace(/[^0-9]/gi, '')
          .replace(new RegExp(`(.{${this.addSpaceAfterChars}})`, "g"), '$1 ').trim();
      } else if (isCodeInput && !/^\d+$/.test(event.data)) {
        // set to null because otherwise '.' character will not be removed
        this.input.value = null;
        this.input.value = this.value;
      }

      this.$emit("update:modelValue", this.input.value);
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    _formatAdditionalLength(maxlength) {
      if (!this.addSpaceAfterChars) {
        return 0;
      }

      return (maxlength / this.addSpaceAfterChars) - 1;
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-input {
  width: 100%;

  // Disable default iOS opacity and color setting on disabled inputs.
  input:disabled {
    color: @color-dark;
    -webkit-text-fill-color: @color-dark;
    opacity: 1;
  } 

  &--label {
    display: block;
    font-size: 15px;
    text-align: left;
    color: @color-dark;
    margin: 8px 0;
  }
  &--container {
    background-color: @color-white;
    border-radius: 10px;
    border: solid 1px @color-light-gray-5;
    color: @color-dark;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    height: 48px;
    position: relative;
  }
  &--icon-container {
    position: relative;
    order: 2;
    line-height: normal;
    padding: 13px 0 13px 20px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  &--icon {
    height: 20px;
    width: 20px;
    fill: @color-blue;
  }
  &--prefix {
    align-self: center;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;
    order: 2;
    padding: 13px 0 13px 13px;
    line-height: normal;
  }
  &--tooltip svg {
    height: 16px;
    width: 16px;
    margin-left: 3px;
    fill: @color-grey-2;
  }
  &--field {
    order: 3;
    width: 100%;
    padding: 13px;
    color: @color-dark;
    flex-shrink: 1;
    border: none;
    background: 0 0;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: normal;
    &[type="number"] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
    &:active,
    &:focus,
    &:hover {
      outline: none;
    }
    &---number-prefixed {
      padding: 13px 0;
    }
    &---password {
      width: 77%;
    }
  }
  &---blue {
    .v-input--container {
      color: @color-blue;
      border-color: @color-blue;
    }
    .v-input--icon {
      fill: @color-blue;
    }

    .v-input--icon-container:after {
      background-color: @color-blue;
    }

    .v-input--field {
      color: @color-blue;
    }
  }
  &---pink {
    .v-input--container {
      color: @color-red;
      border-color: @color-red !important;
    }
    .v-input--icon {
      fill: @color-red;
    }

    .v-input--icon-container:after {
      background-color: @color-red;
    }

    .v-input--field {
      color: @color-red;
    }
  }
  &--disabled {
    border-color: @color-darker-light;
    background-color: @color-light;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    .v-input--field::placeholder {
      color: @color-darker-light;
    }
  }

  &---with-validation {
    .v-input--container {
      height: 48px;
    }
    .v-input---has-error {
      margin-bottom: 0;
    }
    .v-input--error---container {
      position: static;
      bottom: auto;
      left: 0;
      background-color: transparent;
      padding: 3px 12px 6px 0;
      .v-input--error---icon {
        display: none;
      }
    }
    .v-input--container:not(.v-input---has-error) {
      margin-bottom: 32px;
    }
    .v-input--label {
      margin-top: 0;
    }
  }

  &--password-icon-container {
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &--password-icon {
    width: 46px;
    height: 46px;
    fill: @color-blue;
  }
}

// MS Edge has default password show implementation, pseudo-class below disables it
// https://learn.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
::-ms-reveal {
  display: none;
}
</style>
