import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";
import RouterService from "@/services/router.js";
import ResourceService from "@/services/resource.js";
import MetadataService from "@/services/metadata.js";
import ConfigurationService from "@/services/configuration.js";
import GoogleAnalyticsService from "@/services/googleAnalytics.js";
import CampaignApi from "@/api/campaign.js";
import emitter from "@/common/emitter.js";
import constants from "@/constants/index.js";

const beforeEnter = async (to, from, next) => {
  await RouterService.getAutoLoginPromise();

  const isLoggedIn = store.getters["user/isLoggedIn"];
  RouterService.tryPushPageView(to, from);

  if (
    to.meta.alias?.includes(constants.Routes.Login) &&
    to.query.getToken === "true" &&
    (!isLoggedIn || to.query.impersonate === "true")
  ) {
    return next(await RouterService.getTokenAndRedirect(to));
  }

  if (isLoggedIn && to.meta.alias?.includes(constants.Routes.Login)) {
    return next({ path: constants.Routes.Dashboard });
  }

  if (
    isLoggedIn &&
    !to.meta.alias?.includes(constants.Routes.Registration) &&
    store.getters["user/isForceFinishRegistration"]
  ) {
    return next({
      path: store.getters["user/getLanguageRoutes"].Registration,
      hash: constants.Hashes[store.getters["user/getLanguage"].toUpperCase()]
        .RegistrationConfirm,
    });
  }

  if (
    isLoggedIn &&
    !to.meta.alias?.includes(constants.Routes.PasswordRecovery) &&
    store.getters["user/isForcePasswordChange"]
  ) {
    return next({
      path: store.getters["user/getLanguageRoutes"].PasswordRecovery,
      hash: constants.Hashes[store.getters["user/getLanguage"].toUpperCase()]
        .PasswordRecoveryConfirm,
    });
  }

  if (to.meta.authorization && !isLoggedIn) {
    return next({
      path: store.getters["user/getLanguageRoutes"].Login,
      query: RouterService.getReturnQuery(to),
    });
  }

  if (
    to.meta.alias?.includes(constants.Routes.Login) &&
    !isLoggedIn &&
    store.getters["user/tryAutoLogin"] &&
    to.query.useAutoLogin !== "false"
  ) {
    RouterService.redirectToAutoLogin(to);
    return next();
  }

  if (to.query.useAutoLogin === "false") {
    store.commit("user/setTryAutoLogin", false);
  }

  await Promise.all([
    CampaignApi.loadActiveCampaigns(),
    loadResources(),
    loadMainConfiguration(),
    ResourceService.getResources(to.meta.resourceGroups),
    ConfigurationService.getConfigurations(to.meta.configurations),
    store.dispatch("features/fetch"),
  ]);

  if (isLoggedIn) {
    await store.dispatch("menu/updateNavigation");
  }

  if (to.path !== from.path) {
    window.scrollTo(0, 0);
  }

  store.commit("common/setPreviousRoute", from.path);
  store.commit("common/setIsNotFound", false);

  next();
};

const loadResources = () => {
  return store.getters["common/isMainResourcesLoaded"]
    ? Promise.resolve()
    : Promise.all([
        ResourceService.getResources([
          constants.ResourceGroups.ValidationMessages,
          constants.ResourceGroups.NewDashboard,
          constants.ResourceGroups.Footer,
          constants.ResourceGroups.Campaign,
          constants.ResourceGroups.NewPolarModal,
          constants.ResourceGroups.Metadata,
          constants.ResourceGroups.Exceptions,
          constants.ResourceGroups.NewExceptions,
          constants.ResourceGroups.NewRefill,
          constants.ResourceGroups.NotFound,
          constants.ResourceGroups.PrizeCampaign,
        ]),
        store.dispatch("translations/fetch", {
          categories: ["public", "hardware_order"],
          language: store.getters["user/getLanguage"],
        }),
      ]).then(() => {
        store.commit("common/setMainResourcesLoaded", true);
        emitter.emit("mainResourcesLoaded");
      });
};

const loadMainConfiguration = () => {
  return store.getters["common/isMainConfigurationLoaded"]
    ? Promise.resolve()
    : ConfigurationService.getConfigurations([
        constants.Configs.AwardsCampaignActiveFrom,
        constants.Configs.AwardsCampaignActiveTo,
        constants.Configs.ConsentCampaignValidFrom,
        constants.Configs.ConsentCampaignValidTo,
        constants.Configs.WhaleboneManagementEnabled,
      ]).then(() => {
        store.commit("common/setMainConfigurationLoaded", true);
        emitter.emit("mainConfigurationLoaded");
      });
};

const beforeEnterCorporate = async (to, from, next) => {
  const { routeLanguage } = RouterService.getRouteAndActualLanguage(to, from);
  const filteredPath = MetadataService.filterPathRedundancies(to.path);

  if (!to.meta.skipMetadataFetch &&
    !to.meta.fetchMetadaOnlyForLanguages?.some(l => l !== routeLanguage) &&
    (!(await MetadataService.fetchMetadata(filteredPath)) ||
      !MetadataService.isPageActive(filteredPath) ||
      (MetadataService.isPageHidden(filteredPath) &&
        to.query.key !== constants.Settings.HiddenPageKey))
  ) {
    await Promise.all([
      loadResources(),
      loadMainConfiguration(),
      ResourceService.getResources(to.meta.resourceGroups),
    ]);

    if (to.path !== from.path) {
      window.scrollTo(0, 0);
    }

    store.commit("common/setPreviousRoute", from.path);
    store.commit("common/setIsNotFound", true);

    return next();
  }

  if (to.meta.translations) {
    await store.dispatch("translations/fetch", {
      categories: to.meta.translations,
      language: store.getters["user/getLanguage"],
    });
  }
  if (to.path !== from.path && Object.keys(from.meta).length !== 0) {
    GoogleAnalyticsService.pushPageView(
      window.location.origin + to.path + to.hash
    );
  }

  await Promise.all([
    loadResources(),
    loadMainConfiguration(),
    ResourceService.getResources(to.meta.resourceGroups),
  ]);

  const isLoggedIn = store.getters["user/isLoggedIn"];
  if (isLoggedIn) {
    await store.dispatch("menu/updateNavigation");
  }

  if (to.path !== from.path) {
    window.scrollTo(0, 0);
  }

  store.commit("common/setPreviousRoute", from.path);
  store.commit("common/setIsNotFound", false);

  next();
};

const routeOptions = [
  {
    path: constants.Routes.LangProxy,
    meta: { allowAdditionalLanguages: true },
    location: "lang-proxy",
  },
  {
    path: constants.Routes.Dashboard,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [
        constants.ResourceGroups.Consents,
        constants.ResourceGroups.ConsentCampaign,
        constants.ResourceGroups.Whalebone,
        constants.ResourceGroups.NewPricePlans,
      ],
      configurations: [],
      authorization: true,
      metadataId: constants.Meta.Ids.Dashboard,
      useUserInformation: true,
    },
    location: "dashboard",
  },
  {
    path: constants.Routes.PricePlansCancellation,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [constants.ResourceGroups.PricePlansCancellation],
      configurations: [],
      authorization: true,
      metadataId: constants.Meta.Ids.PricePlansCancellation,
    },
    location: "plans-cancellation",
  },
  {
    path: constants.Routes.PortOut,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [
        constants.ResourceGroups.PortOut,
        constants.ResourceGroups.NewSettings,
      ],
      configurations: [],
      authorization: true,
      metadataId: constants.Meta.Ids.PortOut,
    },
    location: "port-out-number",
  },
  {
    path: constants.Routes.Report,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [constants.ResourceGroups.NewReport],
      configurations: [],
      authorization: true,
      metadataId: constants.Meta.Ids.Report,
    },
    location: "reports",
  },
  {
    path: constants.Routes.Invoice,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [constants.ResourceGroups.NewInvoice],
      configurations: [],
      authorization: true,
      metadataId: constants.Meta.Ids.Invoice,
    },
    location: "invoices",
  },
  {
    path: constants.Routes.Settings,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [
        constants.ResourceGroups.NewSettings,
        constants.ResourceGroups.Consents,
        constants.ResourceGroups.ConsentCampaign,
      ],
      configurations: [constants.Configs.CreditCardsPPOrder],
      authorization: true,
      routeKey: constants.Routes.Settings,
      metadataId: constants.Meta.Ids.Settings,
    },
    children: [
      ...[
        {
          path: constants.Routes.SettingsPersonalInformation.ChildPath,
          name: constants.Routes.SettingsPersonalInformation.Name,
          location: "settings-personal-info",
        },
        {
          path: constants.Routes.SettingsPaymentCards.ChildPath,
          name: constants.Routes.SettingsPaymentCards.Name,
          location: "settings-payment-cards",
        },
        {
          path: constants.Routes.SettingsLoginInfo.ChildPath,
          name: constants.Routes.SettingsLoginInfo.Name,
          location: "settings-login-info",
        },
        {
          path: constants.Routes.SettingsLoginHistory.ChildPath,
          name: constants.Routes.SettingsLoginHistory.Name,
          location: "settings-login-history",
        }
      ].map(RouterService.setRouteComponent)
    ],
    location: "settings-parent",
  },
  {
    path: constants.Routes.Whalebone,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [constants.ResourceGroups.Whalebone],
      configurations: [constants.Configs.CreditCardsPPOrder],
      authorization: true,
      routeKey: constants.Routes.Whalebone,
      metadataId: constants.Meta.Ids.Whalebone,
    },
    children: [
      ...[
        {
          path: constants.Routes.WhaleboneReport.ChildPath,
          name: constants.Routes.WhaleboneReport.Name,
          location: "whalebone-report",
        },
        {
          path: constants.Routes.WhaleboneSettings.ChildPath,
          name: constants.Routes.WhaleboneSettings.Name,
          location: "whalebone-settings",
        }
      ].map(RouterService.setRouteComponent),
    ],
    location: "whalebone-parent",
  },
  {
    path: constants.Routes.MyRequests,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [constants.ResourceGroups.NewMyRequests],
      configurations: [],
      authorization: true,
      metadataId: constants.Meta.Ids.MyRequests,
    },
    location: "my-requests",
  },
  {
    path: constants.Routes.OrderConfirmation,
    alias: [
      constants.Routes.PlanOrderConfirmation,
      constants.Routes.VasOrderConfirmation,
      constants.Routes.EN.PlanOrderConfirmation,
      constants.Routes.RU.PlanOrderConfirmation,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewDashboard],
      configurations: [constants.Configs.CreditCardsPPOrder],
      authorization: true,
      metadataId: constants.Meta.Ids.OrderConfirmation,
      alias: [
        constants.Routes.PlanOrderConfirmation,
        constants.Routes.EN.PlanOrderConfirmation,
        constants.Routes.RU.PlanOrderConfirmation,
      ],
      allowAdditionalLanguages: true,
    },
    location: "new-plans-confirmation",
  },
  {
    path: constants.Routes.Plans,
    alias: [constants.Routes.EN.Plans, constants.Routes.RU.Plans],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [
        constants.ResourceGroups.NewPricePlans,
        constants.ResourceGroups.CardSettings,
        constants.ResourceGroups.ComboCampaign,
      ],
      configurations: [constants.Configs.CreditCardsPPOrder],
      authorization: false,
      metadataId: constants.Meta.Ids.Plans,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.Plans,
        constants.Routes.EN.Plans,
        constants.Routes.RU.Plans,
      ],
    },
    location: "plans",
  },
  {
    path: constants.Routes.Refill,
    alias: [constants.Routes.EN.Refill, constants.Routes.RU.Refill],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [
        constants.ResourceGroups.NewRefill,
        constants.ResourceGroups.NewRegistration
      ],
      configurations: [constants.Configs.CreditCardsPPOrder],
      authorization: false,
      metadataId: constants.Meta.Ids.Refill,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.Refill,
        constants.Routes.EN.Refill,
        constants.Routes.RU.Refill,
      ],
    },
    location: "refill",
  },
  {
    path: constants.Routes.Help + "/:group?/:topic?",
    alias: [
      constants.Routes.EN.Help + "/:group?/:topic?",
      constants.Routes.RU.Help + "/:group?/:topic?",
    ],
    beforeEnter,
    props: route => ({
      group: route.params.group,
      topic: route.params.topic,
      query: route.query.q,
    }),
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewHelp],
      metaLinkBreakpoints: [
        {
          breakpoint: constants.ScreenBreakpoints.Sm,
          path: constants.Routes.Help,
          language: constants.LanguagesMap.LT,
        },
        {
          breakpoint: constants.ScreenBreakpoints.Sm,
          path: constants.Routes.EN.Help,
          language: constants.LanguagesMap.EN,
        },
        {
          breakpoint: constants.ScreenBreakpoints.Sm,
          path: constants.Routes.RU.Help,
          language: constants.LanguagesMap.RU,
        }
      ],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.Help,
      allowAdditionalLanguages: true,
      routeKey: constants.Routes.Help,
      alias: [
        constants.Routes.Help + "/:group?/:topic?",
        constants.Routes.EN.Help + "/:group?/:topic?",
        constants.Routes.RU.Help + "/:group?/:topic?",
      ],
    },
    location: "help",
  },
  {
    path: constants.Routes.Login,
    alias: [constants.Routes.EN.Login, constants.Routes.RU.Login],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.Login],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.Login,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.Login,
        constants.Routes.EN.Login,
        constants.Routes.RU.Login,
      ],
    },
    location: "login",
  },
  {
    path: constants.Routes.Registration,
    alias: [constants.Routes.EN.Registration, constants.Routes.RU.Registration],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewRegistration],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.Registration,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.Registration,
        constants.Routes.EN.Registration,
        constants.Routes.RU.Registration,
      ],
    },
    location: "registration",
  },
  {
    path: constants.Routes.RegistrationLanding,
    alias: [
      constants.Routes.EN.RegistrationLanding,
      constants.Routes.RU.RegistrationLanding
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewRegistrationLanding],
      configurations: [],
      authorization: false,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.RegistrationLanding,
        constants.Routes.EN.RegistrationLanding,
        constants.Routes.RU.RegistrationLanding
      ],
    },
    location: "registration-landing",
  },
  {
    path: constants.Routes.RegistrationParent,
    alias: [
      constants.Routes.EN.RegistrationParent,
      constants.Routes.RU.RegistrationParent,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewRegistration],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.Registration,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.RegistrationParent,
        constants.Routes.EN.RegistrationParent,
        constants.Routes.RU.RegistrationParent,
      ],
      routeKey: constants.Routes.RegistrationParent,
    },
    location: "registration-parent",
    children: [
      ...[
        {
          path: "",
          redirect: (to, from) => {
            const { routeLanguage } = RouterService.getRouteAndActualLanguage(to, from);
            const routes = {
              en: constants.Routes.EN.RegistrationEnrollment.Path,
              ru: constants.Routes.RU.RegistrationEnrollment.Path,
              default: constants.Routes.RegistrationEnrollment.Path,
            };

            return routes[routeLanguage] || routes.default;
          },
        },
        {
          path: constants.Routes.RegistrationEnrollment.ChildPath,
          name: constants.Routes.RegistrationEnrollment.Name,
          alias: [
            constants.Routes.EN.RegistrationEnrollment.Path,
            constants.Routes.RU.RegistrationEnrollment.Path,
          ],
          meta: {
            allowAdditionalLanguages: true,
            alias: [
              constants.Routes.RegistrationEnrollment.Path,
              constants.Routes.EN.RegistrationEnrollment.Path,
              constants.Routes.RU.RegistrationEnrollment.Path,
            ]
          },
          location: "registration-enrollment",
          props: ({ query }) => ({
            msisdn: query.msisdn,
            method: query.method,
            useDirect: query.useDirect,
          }),
        },
        {
          path: constants.Routes.RegistrationEnrollmentConfirmation.ChildPath,
          name: constants.Routes.RegistrationEnrollmentConfirmation.Name,
          alias: [
            constants.Routes.EN.RegistrationEnrollmentConfirmation.Path,
            constants.Routes.RU.RegistrationEnrollmentConfirmation.Path,
          ],
          meta: {
            allowAdditionalLanguages: true,
            alias: [
              constants.Routes.RegistrationEnrollmentConfirmation.Path,
              constants.Routes.EN.RegistrationEnrollmentConfirmation.Path,
              constants.Routes.RU.RegistrationEnrollmentConfirmation.Path,
            ]
          },
          location: "registration-enrollment-confirmation",
          props: ({ query }) => ({
            msisdn: query.msisdn,
            method: query.method,
            useDirect: query.useDirect,
          }),
        },
        {
          path: constants.Routes.RegistrationIdentification.ChildPath,
          name: constants.Routes.RegistrationIdentification.Name,
          alias: [
            constants.Routes.EN.RegistrationIdentification.Path,
            constants.Routes.RU.RegistrationIdentification.Path,
          ],
          meta: {
            allowAdditionalLanguages: true,
            alias: [
              constants.Routes.RegistrationIdentification.Path,
              constants.Routes.EN.RegistrationIdentification.Path,
              constants.Routes.RU.RegistrationIdentification.Path,
            ]
          },
          location: "registration-identification",
          props: ({ query }) => ({
            msisdn: query.msisdn,
            guid: query.guid,
            schema: query.schema,
            useDirect: query.useDirect,
          }),
        },
        {
          path: constants.Routes.RegistrationIdentityConfirmation.ChildPath,
          name: constants.Routes.RegistrationIdentityConfirmation.Name,
          alias: [
            constants.Routes.EN.RegistrationIdentityConfirmation.Path,
            constants.Routes.RU.RegistrationIdentityConfirmation.Path,
          ],
          meta: {
            allowAdditionalLanguages: true,
            alias: [
              constants.Routes.RegistrationIdentityConfirmation.Path,
              constants.Routes.EN.RegistrationIdentityConfirmation.Path,
              constants.Routes.RU.RegistrationIdentityConfirmation.Path,
            ]
          },
          location: "registration-identity-confirmation",
          props: ({ query }) => ({
            msisdn: query.msisdn,
            guid: query.guid,
            schema: query.schema,
            success: (query.success || "").toLowerCase(),
          }),
        },
      ].map(RouterService.setRouteComponent)
    ],
  },
  {
    path: constants.Routes.RegistrationStatus.Path,
    name: constants.Routes.RegistrationStatus.Name,
    alias: [
      constants.Routes.EN.RegistrationStatus,
      constants.Routes.RU.RegistrationStatus,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewRegistration],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.Registration,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.RegistrationStatus.Path,
        constants.Routes.EN.RegistrationStatus,
        constants.Routes.RU.RegistrationStatus,
      ],
    },
    props: ({ query }) => ({
      msisdn: query.msisdn,
      schema: query.schema,
      status: query.status,
      guid: query.guid,
      workflowId: query.workflowId,
    }),
    location: "registration-status",
  },
  {
    path: constants.Routes.RegistrationDashboard,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [
        constants.ResourceGroups.NewRegistration,
      ],
      configurations: [],
      authorization: true,
      routeKey: constants.Routes.RegistrationDashboard,
      metadataId: constants.Meta.Ids.RegistrationDashboard,
    },
    location: "registration-dashboard",
  },
  {
    path: constants.Routes.PasswordRecovery,
    alias: [
      constants.Routes.EN.PasswordRecovery,
      constants.Routes.RU.PasswordRecovery,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.PasswordRecovery],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.PasswordRecovery,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.PasswordRecovery,
        constants.Routes.EN.PasswordRecovery,
        constants.Routes.RU.PasswordRecovery,
      ],
    },
    location: "password-recovery",
  },
  {
    path: constants.Routes.RefillStatus,
    beforeEnter,
    props: route => ({
      transactionId: route.query.transactionId,
      campaignName: route.query.campaignName,
      phone: route.query.phone,
      amount: route.query.amount,
      isSuccess: route.params.status === "success",
      isRefillCampaign: route.query.campaignName === constants.AvailableCampaigns.Refill,
    }),
    meta: {
      layout: "default",
      resourceGroups: [
        constants.ResourceGroups.Refill,
        constants.ResourceGroups.SimRefillCampaign,
      ],
      configurations: [
        constants.Configs.RefillCampaignStartDate,
        constants.Configs.RefillCampaignEndDate,
      ],
      authorization: false,
      metadataId: constants.Meta.Ids.RefillStatus,
      allowAdditionalLanguages: true,
    },
    location: "refill-status",
  },
  {
    path: constants.Routes.Campaign.Path,
    name: constants.Routes.Campaign.Name,
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [],
      configurations: [constants.Configs.CreditCardsPPOrder],
      authorization: false,
      metadataId: constants.Meta.Ids.Campaign,
    },
    location: "campaign",
  },
  {
    path: constants.Routes.EmailConfirmation,
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.NewSettings],
      configurations: [],
      authorization: false,
      metadataId: constants.Meta.Ids.EmailConfirmation,
    },
    location: "email-confirmation",
  },
  {
    path: constants.Routes.Home,
    alias: [constants.Routes.EN.Home, constants.Routes.RU.Home],
    beforeEnter: beforeEnterCorporate,
    meta: {
      layout: "default",
      skipMetadataFetch: true,
      translations: ["home"],
      metadataId: constants.Meta.Ids.Home,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.Home,
        constants.Routes.EN.Home,
        constants.Routes.RU.Home,
      ],
    },
    location: "home",
  },
  {
    path: constants.Routes.Discounts,
    beforeEnter: beforeEnterCorporate,
    meta: {
      layout: "default",
      translations: ["discount_list"],
    },
    location: "discount-list",
  },
  {
    path: constants.Routes.ForeignCallRates,
    alias: [
      constants.Routes.EN.ForeignCallRates,
      constants.Routes.RU.ForeignCallRates,
    ],
    beforeEnter: beforeEnterCorporate,
    meta: {
      layout: "default",
      translations: ["foreign_call_rates"],
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.ForeignCallRates,
        constants.Routes.EN.ForeignCallRates,
        constants.Routes.RU.ForeignCallRates,
      ],
    },
    location: "foreign-call-rates",
  },
  {
    path: constants.Routes.ForeignCallRatesAlias,
    redirect: constants.Routes.ForeignCallRates,
  },
  {
    path: constants.Routes.ForeignCallRatesAlias2,
    redirect: constants.Routes.ForeignCallRates,
  },
  {
    path: constants.Routes.ForeignCallRatesAlias3,
    redirect: constants.Routes.ForeignCallRates,
  },
  {
    path: constants.Routes.PildykIsCheapest,
    beforeEnter: beforeEnterCorporate,
    meta: {
      layout: "default",
      translations: ["pildyk_is_cheapest"],
    },
    location: "pildyk-is-cheapest",
  },
  {
    path: constants.Routes.MobileGameRules,
    alias: [
      constants.Routes.EN.MobileGameRules,
      constants.Routes.RU.MobileGameRules,
    ],
    meta: {
      layout: "default",
      translations: ["giveaway_delivery"],
      allowAdditionalLanguages: true,
      useCorporateBeforeEach: true,
      overrideLocalStorageLanguage: true,
      alias: [
        constants.Routes.MobileGameRules,
        constants.Routes.EN.MobileGameRules,
        constants.Routes.RU.MobileGameRules,
      ],
    },
    location: "content-type",
  },
  {
    path: constants.Routes.PagePreview,
    beforeEnter,
    meta: {
      layout: "default",
      translations: ["giveaway_delivery"],
      useCorporateBeforeEach: true,
      skipMetadataFetch: true,
    },
    location: "content-type",
  },
  {
    path: constants.Routes.CoverageMap,
    beforeEnter: beforeEnterCorporate,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.CoverageMap],
    },
    location: "coverage-map",
  },
  {
    path: constants.Routes.IsicLanding,
    alias: [constants.Routes.EN.IsicLanding, constants.Routes.RU.IsicLanding],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.IsicCampaign],
      configurations: [
        constants.Configs.IsicCampaignValidFrom,
        constants.Configs.IsicCampaignValidTo,
        constants.Configs.IsicPricePlanId,
      ],
      authorization: false,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.IsicLanding,
        constants.Routes.EN.IsicLanding,
        constants.Routes.RU.IsicLanding,
      ],
    },
    location: "isic-landing",
  },
  {
    path: constants.Routes.IsicForm,
    alias: [constants.Routes.EN.IsicForm, constants.Routes.RU.IsicForm],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [
        constants.ResourceGroups.NewPricePlans,
        constants.ResourceGroups.CardSettings,
        constants.ResourceGroups.IsicCampaign,
      ],
      configurations: [
        constants.Configs.IsicCampaignValidFrom,
        constants.Configs.IsicCampaignValidTo,
        constants.Configs.IsicPricePlanId,
      ],
      authorization: true,
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.IsicForm,
        constants.Routes.EN.IsicForm,
        constants.Routes.RU.IsicForm,
      ],
    },
    location: "isic-form",
  },
  {
    path: constants.Routes.DeviceInformation,
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.DeviceInformation],
      configurations: [],
    },
    location: "device-information",
  },
  {
    path: constants.Routes.SelfcareAutoLoginSmsChallenge,
    alias: [
      constants.Routes.EN.SelfcareAutoLoginSmsChallenge,
      constants.Routes.RU.SelfcareAutoLoginSmsChallenge,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.SmsChallenge],
      configurations: [],
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.SelfcareAutoLoginSmsChallenge,
        constants.Routes.EN.SelfcareAutoLoginSmsChallenge,
        constants.Routes.RU.SelfcareAutoLoginSmsChallenge,
      ],
    },
    location: "auto-login-sms-challenge",
  },
  {
    path: constants.Routes.ESimLanding,
    beforeEnter,
    alias: [
      constants.Routes.EN.ESimLanding,
      constants.Routes.RU.ESimLanding,
    ],
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.ESim],
      allowAdditionalLanguages: true,
      fetchMetadaOnlyForLanguages: [constants.DefaultLanguage],
      alias: [
        constants.Routes.ESimLanding,
        constants.Routes.EN.ESimLanding,
        constants.Routes.RU.ESimLanding,
      ],
    },
    location: "e-sim",
  },
  {
    path: constants.Routes.ESimPurchase,
    alias: [
      constants.Routes.EN.ESimPurchase,
      constants.Routes.RU.ESimPurchase,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [
        constants.ResourceGroups.Login,
        constants.ResourceGroups.ESim,
      ],
      configurations: [constants.Configs.CreditCardsPPOrder],
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.ESimPurchase,
        constants.Routes.EN.ESimPurchase,
        constants.Routes.RU.ESimPurchase,
      ],
    },
    location: "e-sim-purchase",
  },
  {
    path: constants.Routes.ESimPurchaseQr.Path,
    name: constants.Routes.ESimPurchaseQr.Name,
    alias: [
      constants.Routes.EN.ESimPurchaseQr,
      constants.Routes.RU.ESimPurchaseQr,
    ],
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.ESim],
      configurations: [],
      allowAdditionalLanguages: true,
      alias: [
        constants.Routes.ESimPurchaseQr.Path,
        constants.Routes.EN.ESimPurchaseQr,
        constants.Routes.RU.ESimPurchaseQr,
      ],
    },
    location: "e-sim-purchase-qr",
    props: true,
  },
  {
    path: constants.Routes.ESimChangeInformation,
    beforeEnter,
    meta: {
      layout: "main",
      resourceGroups: [constants.ResourceGroups.ESim],
      configurations: [constants.Configs.ESimChangePricePlanId],
      allowAdditionalLanguages: false,
      authorization: true,
      alias: [
        constants.Routes.ESimChangeInformation,
      ],
      useUserInformation: true,
    },
    location: "e-sim-change-information",
  },
  {
    path: constants.Routes.ESimChange,
    beforeEnter,
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.ESim],
      configurations: [constants.Configs.ESimChangePricePlanId],
      allowAdditionalLanguages: false,
      authorization: true,
      alias: [
        constants.Routes.ESimChange,
      ],
    },
    location: "e-sim-change",
  },
  {
    path: constants.Routes.WhyPildyk,
    beforeEnter,
    alias: [
      constants.Routes.EN.WhyPildyk,
      constants.Routes.RU.WhyPildyk,
    ],
    meta: {
      layout: "default",
      resourceGroups: [constants.ResourceGroups.WhyPildyk],
      allowAdditionalLanguages: false,
      alias: [
        constants.Routes.WhyPildyk,
        constants.Routes.EN.WhyPildyk,
        constants.Routes.RU.WhyPildyk,
      ]
    },
    location: "why-pildyk",
  },
  {
    path: constants.Routes.ESimOldLanding,
    redirect: constants.Routes.ESimLanding,
  },
  {
    path: constants.Routes.EN.ESimOldLanding,
    redirect: constants.Routes.EN.ESimLanding,
  },
  {
    path: constants.Routes.RU.ESimOldLanding,
    redirect: constants.Routes.RU.ESimLanding,
  },
  {
    path: constants.Routes.ESimOldChangeInformation,
    redirect: constants.Routes.ESimChangeInformation,
  },
  {
    path: constants.Routes.ESimOldChange,
    redirect: constants.Routes.ESimChange,
  },
  {
    path: constants.Routes.ESimOldPurchase,
    redirect: constants.Routes.ESimPurchase
  },
  {
    path: constants.Routes.EN.ESimOldPurchase,
    redirect: constants.Routes.EN.ESimPurchase
  },
  {
    path: constants.Routes.RU.ESimOldPurchase,
    redirect: constants.Routes.RU.ESimPurchase
  },
  {
    path: constants.Routes.ESimOldPurchaseQr,
    redirect: to => {
      return constants.Routes.ESimPurchaseQr.Path.replace(':transactionId', to.params.transactionId)
    }
  },
  {
    path: constants.Routes.EN.ESimOldPurchaseQr,
    redirect: to => {
      return constants.Routes.EN.ESimPurchaseQr.replace(':transactionId', to.params.transactionId)
    }
  },
  {
    path: constants.Routes.RU.ESimOldPurchaseQr,
    redirect: to => {
      return constants.Routes.RU.ESimPurchaseQr.replace(':transactionId', to.params.transactionId)
    }
  },
  {
    path: "/:catchAll(.*)",
    meta: {
      layout: "default",
      translations: ["giveaway_delivery"],
      useCorporateBeforeEach: true,
      allowAdditionalLanguages: true,
      isDynamic: true,
    },
    location: "content-type",
  },
];

const routes = routeOptions.map(RouterService.setRouteComponent);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { actualLanguage, routeLanguage } =
    RouterService.getRouteAndActualLanguage(to, from);

  const isDifferentLanguage = routeLanguage !== actualLanguage;

  if (isDifferentLanguage && from.name === null
      && !to.fullPath.startsWith(constants.Routes.RefillStatusShortcut)
  ) {
    store.commit("user/setLanguage", routeLanguage);
  }

  if (store.getters["user/isLoggedIn"]) {
    await store.dispatch("user/setActionReminders");
  }

  await store.dispatch("shortLinks/fetch");

  const shortLinkPath = store.getters["shortLinks/tryGetNewPath"](to.fullPath);

  if (shortLinkPath) {
    return next(shortLinkPath);
  }

  if (store.getters["common/isNewVersionAvailable"]) {
    document.location.href = to.path;
  }

  if (
    !to.meta.alias?.includes(constants.Routes.Login) &&
    !to.meta.alias?.includes(constants.Routes.PasswordRecovery) &&
    !to.meta.alias?.includes(constants.Routes.Registration)
  ) {
    sessionStorage.removeItem(constants.SessionStorage.ReturnRoute);
  }

  const hash = RouterService.transformHashFromRouteLanguageToActual(
    to.hash,
    routeLanguage,
    actualLanguage
  );

  if (isDifferentLanguage
    && (to.meta.isDynamic || !to.meta.allowAdditionalLanguages)) {
    return next({ path: constants.Routes.Home, replace: true });
  }

  if (to.meta.useCorporateBeforeEach && !(isDifferentLanguage && to.meta.alias)) {
    await beforeEnterCorporate(to, from, next);
  } else if ((hash || to.hash === "#_=_") && to.hash !== hash) {
    next({
      path: to.path,
      query: to.query,
      hash,
      replace: true
    });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to.query.key) {
    window.history.replaceState({}, "", to.path);
  }

  if (to?.meta?.routeKey) {
    return;
  }

  if (to.path === from.path) {
    return;
  }

  window.scroll(0, 0);
});

export default router;
