<template>
  <div
    :key="mainKey"
    class="v-layout-wrapper"
    :class="{ 'v-page-content---hidden': !loaded }"
  >
    <t2-header :dark="headerDarkMode" />
    <main class="main">
      <component
        :is="layout"
        @open-polar-modal="openPolarModal"
      >
        <router-view
          :key="currentRoute"
          @open-polar-modal="openPolarModal"
          @invoke-not-found="isNotFound = true"
        />
      </component>
      <t2-polar-modal :pole="polarPopupData">
        <div
          v-show="loaded"
          class="popup--wrapper"
          style="display: none"
        >
          <t2-heading
            level="2"
            class="polar--heading"
          >
            {{ polarPopupData.title }}
          </t2-heading>
          <p class="polar--sub-heading" v-html="polarPopupData.description" />
          <div class="popup--actions">
            <t2-button
              class="margin-auto"
              :href="polarPopupData.href"
              :default="!!polarPopupData.href"
              @click="emitter.emit('close-modal', { id: polarPopupData.id })"
            >
              {{ polarPopupData.closeButton }}
            </t2-button>
          </div>
        </div>
      </t2-polar-modal>
      <t2-1000-samsung-medal-receive-modal
        v-if="medalReceiveModalData"
        :data="medalReceiveModalData"
        @button-click="handleReceiveMedalClick"
      />
      <t2-information-popups />
    </main>
    <div
      v-if="resourcesLoaded"
      v-show="showFooter"
      class="newsletter-section"
    >
      <t2-newsletter-new
        ref="newsletter"
        :key="'newsletter-' + newsletterSettings.key"
        :data="newsletterData"
        :show="newsletterSettings.show"
        :animate="newsletterSettings.animate"
        @submit="handleNewslettersOrderClick"
      />
    </div>
    <section
      v-if="resourcesLoaded"
      v-show="showFooter"
      style="overflow: hidden;"
    >
      <footer-wrapper @cookies-click="handleCookieClick" />
    </section>
    <t2-cookie-agreement
      v-if="cookieAgreement.show"
      :data="cookieAgreement.data"
      @click="handleCookieAgreementClick"
    />
    <t2-confirm-modal
      :id="logoutModal"
      :data="confirmModal"
      @confirm-click="confirmLogout"
    />
    <t2-payment-card-confirmation-modal v-if="resourcesLoaded" />
    <t2-cookie-bot-loader :is-active="isCookieLoaderActive" />
  </div>
</template>
<script>
import T2Button from "@atoms/Button/Button.vue";
import T2Heading from "@atoms/Heading/Heading.vue";
import T2PolarModal from "@organisms/PolarModal/PolarModal.vue";
import T2CookieBotLoader from "@organisms/CookieBotLoader/CookieBotLoader.vue";
import T2CookieAgreement from "@organisms/CookieAgreement/CookieAgreement.vue";
import T21000SamsungMedalReceiveModal from "@organisms/Campaigns/1000Samsung/Modals/MedalReceiveModal/MedalReceiveModal.vue";
import T2ConfirmModal from "@organisms/Modals/ConfirmModal/ConfirmModal.vue";
import T2NewsletterNew from "@templates/Newsletter/Newsletter.vue";
import T2NotFound from "@/views/not-found/index.vue";
import DefaultLayout from "@/layouts/default/index.vue";
import MainLayout from "@/layouts/main/index.vue"
import T2InformationPopups from "@/components/information-popups/index.vue";
import T2PaymentCardConfirmationModal from "@/components/payment-card-confirmation-modal/index.vue";
import T2Header from "@/components/header/index.vue";
import FooterWrapper from "@/components/footer/index.vue";
import AppVersionService from "@/services/appVersion.js";
import PolarModalService from "@/services/polarModal.js";
import CookieAgreementService from "@/services/cookieAgreement.js";
import CookieBotService from "@/services/cookieBot.js";
import MetadataService from "@/services/metadata.js";
import LoaderService from "@/services/loader.js";
import UserService from "@/services/user.js";
import { useMetaRobot } from "@/composables/useMetaRobot.js";
import { useMetaLanguageLinks } from "@/composables/useMetaLanguageLinks.js";
import svg4everybody from "@/common/svg4everybody.js";
import axiosClient from "@/common/axiosClient.js";
import constants from "@/constants/index.js";

export default {
  components: {
    T2Button,
    T2Heading,
    T2PolarModal,
    T2CookieBotLoader,
    T2CookieAgreement,
    T21000SamsungMedalReceiveModal,
    T2NewsletterNew,
    T2NotFound,
    T2InformationPopups,
    T2ConfirmModal,
    T2PaymentCardConfirmationModal,
    DefaultLayout,
    MainLayout,
    T2Header,
    FooterWrapper,
  },
  inject: ["emitter"],
  setup() {
    if (window.location.hostname.includes("staging")) {
      useMetaRobot(true, true);
    }

    const { setLinksUp, cleanLinksUp } = useMetaLanguageLinks();

    return {
      setLinksUp,
      cleanLinksUp
    };
  },
  data() {
    return {
      loaded: false,
      resourcesLoaded: false,
      mainKey: 1,
      medalReceiveModalData: null,
      logoutModal: constants.Modals.ConfirmLogOut,
      isNotFound: false,
      isCookieLoaderActive: false,
      isCookiebotSettings: false,
      isCookiebotRestart: false,
      confirmModal: {},
      newsletterData: {},
      polarPopupData: {
        id: null,
        title: null,
        description: null,
        closeButton: null,
        imageUrl: null,
        href: null
      },
      newsletterSettings: {
        key: 1,
        animate: false,
        show: false,
      },
      cookieAgreement: {
        data: null,
        show: false,
      },
    };
  },
  computed: {
    layout() {
      if (this.isNotFound) {
        return "t2-not-found";
      }

      return (this.$route.meta.layout || "default") + "-layout";
    },
    withNavigation() {
      return this.$route.meta.withNavigation || false;
    },
    isDefaultLanguage() {
      return this.$store.getters["user/isDefaultLanguage"];
    },
    currentRoute() {
      return this.$route?.meta?.routeKey || this.$route.path;
    },
    showFooter() {
      return !this.$route.meta.layoutConfiguration?.hideFooter;
    },
    headerDarkMode() {
      if (this.isNotFound) {
        return false;
      }

      return this.$route.meta.layoutConfiguration?.darkMode;
    },
  },
  watch: {
    $route(to) {
      this.isNotFound = this.$store.getters["common/isNotFound"];
      this.cleanLinksUp();

      if (!this.isNotFound) {
        this.setLinksUp(to);
      }

      const metaData = to.meta.metadataId
        ? MetadataService.formatMetadata({
            title: constants.Resources.Metadata[`${to.meta.metadataId}Title`],
            content: constants.Resources.Metadata[`${to.meta.metadataId}Description`],
          })
        : MetadataService.getMetadataByAlias(to.path);

      return MetadataService.setMetadata(metaData);
    },
  },
  created() {
    window.addEventListener("CookiebotOnDialogInit", this.handleCookieBanner)
    this.emitter.on("enable-animation", this.enableAnimation);
    this.emitter.on("disable-animation", this.disableAnimation);

    const urlParams = new URLSearchParams(window.location.search);
    const isLocalLanguage = window.location.pathname.startsWith(constants.Routes.RefillStatusShortcut)
      || window.location.pathname.startsWith(constants.Routes.ESimPurchaseQrShortcut)
      || urlParams.get("returnUrl")
      || urlParams.get("getToken")
      || urlParams.get("ppPurchaseResult")
      || urlParams.get("useAutoLogin");
    const routePrefix = window.location.pathname.split("/")[1];
    const routeLanguage = constants.Languages.includes(routePrefix)
      ? routePrefix
      : constants.DefaultLanguage;
    const localLanguage = (localStorage.getItem("language") ?? constants.DefaultLanguage);

    this.$store.commit("user/setLanguage", isLocalLanguage ? localLanguage : routeLanguage);
  },
  mounted() {
    window.addEventListener("scroll", this.reveal);
    window.addEventListener("pageshow", this.handlePageShow);
    this.emitter.on("navigationClick", this.handleNavigationClick);
    this.emitter.on("mainResourcesLoaded", this.handleMainResourcesLoad);
    this.emitter.on("open-medal-modal", this.openMedalModal);

    return Promise.all([
      this.$store.dispatch(
        "footer/fetch",
        this.$store.getters["user/getLanguage"]
      ),
      this.$store.dispatch(
        "header/fetch",
        this.$store.getters["user/getLanguage"]
      ),
      this.$store.dispatch("features/fetch"),
      this.$store.dispatch("user/setForceUnfinishedUserMoves"),
    ]).then(() => {
      this.reveal();
      AppVersionService.watchVersion();
      svg4everybody.init();
    });
  },
  beforeUnmount() {
    window.removeEventListener("CookiebotOnDialogInit", this.handleCookieBanner);
    window.removeEventListener("scroll", this.reveal);
    window.removeEventListener("pageshow", this.handlePageShow);
    window.removeEventListener(constants.SessionStorage.CookiebotLoadFailed, this.showCookieAgreement);
    this.emitter.off("enable-animation", this.enableAnimation);
    this.emitter.off("disable-animation", this.disableAnimation);
    this.emitter.off("navigationClick", this.handleNavigationClick);
    this.emitter.off("mainResourcesLoaded", this.handleMainResourcesLoad);
    this.emitter.off("open-medal-modal", this.openMedalModal);
    this.emitter.all.clear();
  },
  methods: {
    handleMainResourcesLoad(reload) {
      window.addEventListener(constants.SessionStorage.CookiebotLoadFailed, this.showCookieAgreement);

      this.setNewsLetterData();
      this.resourcesLoaded = true;

      return LoaderService.hideLoader().then(() => {
        if (sessionStorage.getItem(constants.SessionStorage.CookiebotLoadFailed)) {
          this.showCookieAgreement();
        }

        this.loaded = true;

        if (!reload) {
          return;
        }

        this.mainKey++;
      });
    },
    openMedalModal(data) {
      this.medalReceiveModalData = {
        backgroundImage: constants.Images.AwardsCampaign.MedalReceiveBackground,
        image: data.image,
        title:
          data.title ?? constants.Resources.AwardsCampaign.MedalReceiveTitle,
        points: data.points,
        description: data.description.replace("{0}", data.points),
        button: constants.Resources.AwardsCampaign.OkButton,
        showCloseButton: data.showCloseButton,
      };
      this.$nextTick(() => {
        this.emitter.emit(constants.ModalControl.Open, { id: constants.AwardsCampaign.Modals.MedalReceive });
      });
    },
    handlePageShow(event) {
      if (event.persisted) {
        window.location.reload();
      }
    },
    openPolarModal(data) {
      const types = [
        constants.PolarPopupType.Success,
        constants.PolarPopupType.Error
      ];

      if (types.includes(data.id)) {
        this.polarPopupData.id = data.id;
        this.polarPopupData.title = data.title;
        this.polarPopupData.description = data.description;
        this.polarPopupData.closeButton = data.closeButton;
        this.polarPopupData.imageUrl = data.imageUrl;
        this.polarPopupData.href = data.href;
      }

      this.$nextTick(() => {
        this.emitter.emit(constants.ModalControl.Open, this.polarPopupData);
      });
    },
    handleNavigationClick(item) {
      if (item.url === constants.Routes.Logout) {
        return this.openLogoutConfirmModal();
      }

      if (item.spa && this.$route.path !== item.url) {
        return this.$router.push({ path: item.url });
      }

      if (this.$route.path === item.url) {
        return;
      }

      if (item.url) {
        window.location.href = item.url;
      }
    },
    handleNewslettersOrderClick({ error, email }) {
      if (error) {
        return;
      }

      this.newsletterData.button.loader = true;

      return axiosClient.post("/user-information/newsletter/order", { email })
        .then(() => {
          this.openPolarModal(
            PolarModalService.formatSuccessData(
              constants.Resources.NewDashboard.NewslettersMemberSavedSuccessfullyTitle,
              constants.Resources.NewDashboard.NewslettersMemberSavedSuccessfully
            )
          );
          this.$refs.newsletter.email = null;
        })
        .catch((error) => this.openPolarModal(PolarModalService.formatErrorData(error.response)))
        .finally(() => (this.newsletterData.button.loader = false));
    },
    setNewsLetterData() {
      this.newsletterData = {
        title: constants.Resources.NewDashboard.NewslettersLabelText,
        description: constants.Resources.NewDashboard.NewslettersDescription,
        image: constants.Images.Newsletter.Url,
        button: {
          text: constants.Resources.NewDashboard.NewslettersOrder,
          loader: false,
        },
        input: {
          placeholder: constants.Resources.NewDashboard.NewslettersEmail,
          validationError:
            constants.Resources.NewDashboard.NewslettersInvalidEmailMessage,
          emptyError:
            constants.Resources.NewDashboard.NewslettersEmptyEmailMessage,
        },
      };
    },
    isOnScreen(element) {
      const position = element.getBoundingClientRect();

      return position.top < window.innerHeight && position.bottom >= 0;
    },
    reveal() {
      if (this.newsletterSettings.animate && this.isOnScreen(this.$refs.newsletter.$el)) {
        this.newsletterSettings.show = true;
      }
    },
    enableAnimation() {
      this.newsletterSettings.animate = true;
      this.newsletterSettings.show = false;
      this.newsletterSettings.key++;
    },
    disableAnimation() {
      this.newsletterSettings.animate = false;
      this.newsletterSettings.show = false;
      this.newsletterSettings.key++;
    },
    handleReceiveMedalClick() {
      this.emitter.emit(constants.ModalControl.Close, { id: constants.AwardsCampaign.Modals.MedalReceive });

      if (this.$route.path === constants.Routes.AwardsCampaignInfo) {
        return;
      }

      return this.$router.push(constants.Routes.AwardsCampaignInfo);
    },
    showCookieAgreement() {
      this.cookieAgreement.data = CookieAgreementService.formatCookieAgreementData();
      this.cookieAgreement.show = CookieAgreementService.showCookieAgreement();
    },
    handleCookieAgreementClick() {
      return CookieAgreementService.handleCookieAgreementClick(this);
    },
    openLogoutConfirmModal() {
      this.confirmModal = {
        image: constants.Images.Confirmation.Url,
        title: constants.Resources.NewDashboard.LogoutConfirmationTitle,
        decline: constants.Resources.NewDashboard.Cancel,
        confirm: constants.Resources.NewDashboard.Confirm,
      };
      this.emitter.emit(constants.ModalControl.Open, { id: this.logoutModal });
    },
    confirmLogout() {
      this.emitter.emit(constants.Events.UpdateInformationBanners);
      this.emitter.emit(constants.ModalControl.Close, { id: this.logoutModal });
      this.emitter.emit("close-mobile-navigations");
      this.emitter.emit("clear-user-specific-banners");

      this.$store.commit("menu/resetMenu");
      this.$store.commit("payments/resetCardDetails");
      this.$store.commit("whalebone/setRequestPromise", null);

      return UserService.logOut();
    },
    handleCookieClick() {
      this.isCookiebotSettings = true;

      return CookieBotService.showCookieBot();
    },
    handleCookieBanner() {
      if (this.isCookiebotSettings) {
        this.isCookieLoaderActive = true;

        return setTimeout(() => { this.isCookieLoaderActive = false }, 4000);
      }

      if (!this.isDefaultLanguage && !this.isCookiebotRestart) {
        this.isCookiebotRestart = true;

        return CookieBotService.showCookieBot();
      }
    }
  },
};
</script>
