<template>
  <div class="checkbox" :class="{ disabled }">
    <input
      :id="name"
      class="checkbox--input"
      type="checkbox"
      :name="name"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('checkbox-change', !checked)"
    />
    <label class="checkbox--item" :for="name" />
    <label class="checkbox--label" :for="name" v-html="label" />
  </div>
</template>
<script>
export default {
  props: {
    name: {
      default: "checkbox",
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["checkbox-change"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.checkbox {
  display: flex;
  &--item {
    background-color: @color-white;
    border-radius: 4px;
    border: solid 1px @color-dark;
    cursor: pointer;
    height: 22px;
    position: relative;
    top: 1px;
    transition: all 0.2s ease;
    flex: 0 0 22px;
    @media @tablet {
      height: 20px;
      flex: 0 0 20px;
    }
    &:hover {
      border-color: @color-blue;
      transition: all 0.5s ease;
    }
    &:after {
      border-bottom: 3px solid @color-blue;
      border-right: 3px solid @color-blue;
      content: "";
      height: 12px;
      left: 5px;
      opacity: 0;
      position: absolute;
      top: 1px;
      transform: rotate(45deg) scale(0);
      transition: all 0.3s ease 0.15s;
      width: 7px;
      border-radius: 2px;
      @media @tablet {
        border-bottom: 3px solid @color-blue;
        border-right: 3px solid @color-blue;
        height: 10px;
        width: 6px;
        left: 4.5px;
        top: 1px;
        border-radius: 2px;
      }
    }
  }
  &--input {
    display: none;
    &:checked ~ .checkbox--item {
      animation: jelly 0.6s ease;
      border-color: @color-blue;
      &:after {
        border-color: @color-blue;
        opacity: 1;
        transform: rotate(45deg) scale(1);
      }
    }
  }
  &--label {
    cursor: pointer;
    .BP-light;
    font-size: 15px;
    font-weight: normal;
    margin-left: 12px;
    align-self: center;
    @media @tablet {
      font-size: 13px;
    }
  }

  &---nowrap &--label {
    white-space: nowrap;
  }
  &.disabled {
    .checkbox--item {
      &:hover {
        border-color: @color-light-gray-5;
        cursor: default;
      }
    }
    .checkbox--label {
      cursor: default;
    }
  }
}
</style>
