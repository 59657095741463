<template>
  <nav
    v-if="data.submenu && data.submenu.length > 0"
    class="drop-menu"
    :class="{ 'drop-menu---active': isSubActive }"
    aria-label="Navigation drop menu"
  >
    <div
      ref="drop-menu"
      class="drop-menu--item"
    >
      <div class="drop-menu--wrapper">
        <ul class="drop-menu--list">
          <li class="drop-menu--list-item">
            <div class="drop-menu--dashboard-button">
              <a
                href="#"
                class="drop-menu--dashboard-text"
                @click.prevent="handleButtonToggle"
              >
                <t2-svg
                  class="drop-menu--dashboard-icon"
                  icon-id="user-icon-filled"
                />
                {{ data.default.submenu.name }}
              </a>
            </div>
          </li>
        </ul>
        <ul
          v-for="(links, i) in data.submenu"
          :key="`dmi-${i}`"
          class="drop-menu--list"
        >
          <li
            v-for="(link, j) in links.list"
            :key="`dmi-${i}-${j}`"
            class="drop-menu--list-item"
            :class="{
              'drop-menu--list-item---red': link.red,
              'drop-menu--list-item---selected': link.selected
            }"
          >
            <a
              class="drop-menu--link"
              :href="link.url || '#'"
              @click.prevent="handleLinkToggle(link)"
            >
              <t2-svg
                v-if="link.iconId"
                :iconId="link.iconId"
                class="drop-menu--link-image"
              />
              <div v-loader="link.loader">
                <span>{{ link.name }}</span>
              </div>
              <t2-badge v-if="link.badge && link.badge.length > 0" :text="link.badge" />
              <t2-svg 
                v-if="!link.event"
                class="drop-menu--icon"
                icon-id="menu-arrow-right"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="drop-menu--overlay" />
  </nav>
  <nav
    class="mobile-menu"
    :class="{ 'mobile-menu---open': isMainActive }"
    aria-label="Mobile navigation drop menu"
  >
    <div class="mobile-menu--container">
      <ul class="mobile-menu--list">
        <li
          v-if="data.default.registration"
          class="mobile-menu--item mobile-menu--item---button"
        >
          <div class="mobile-menu--item-button-container">
            <t2-button
              secondary
              default
              :href="data.default.registration.url"
              class="mobile-menu--item-button"
              @click.prevent="$emit('link-click', data.default.registration)"
            >
              {{ data.default.registration.name }}
            </t2-button>
          </div>
        </li>
        <li
          v-for="(link, index) in data.links"
          :key="'mmi-' + index"
          class="mobile-menu--item"
        >
          <a
            class="mobile-menu--link"
            :href="link.href"
            @click.prevent="$emit('link-click', link)"
          >
            <span class="mobile-menu--text">{{ link.name }}</span>
          </a>
        </li>
      </ul>
      <div class="mobile-menu--language-select-wrapper">
        <t2-select-dropdown
          :options="data.default.languages"
          @option-click="$emit('language-select', $event)"
        />
      </div>
      <div v-if="data.default.socials" class="mobile-menu--social">
        <a
          v-if="data.default.socials.facebook"
          class="mobile-menu--social-link"
          :href="data.default.socials.facebook.url"
          @click.prevent="$emit('link-click', data.default.socials.facebook)"
        >
          <t2-svg
            class="mobile-menu--social-icon"
            icon-id="social-facebook"
          />
        </a>
        <a
          v-if="data.default.socials.instagram"
          class="mobile-menu--social-link"
          :href="data.default.socials.instagram.url"
          @click.prevent="$emit('link-click', data.default.socials.instagram)"
        >
          <t2-svg
            class="mobile-menu--social-icon"
            icon-id="social-instagram"
          />
        </a>
        <a
          v-if="data.default.socials.youtube"
          class="mobile-menu--social-link"
          :href="data.default.socials.youtube.url"
          @click.prevent="$emit('link-click', data.default.socials.youtube)"
        >
          <t2-svg
            class="mobile-menu--social-icon"
            icon-id="social-youtube"
          />
        </a>
        <a
          v-if="data.default.socials.tiktok"
          class="mobile-menu--social-link"
          :href="data.default.socials.tiktok.url"
          @click.prevent="$emit('link-click', data.default.socials.tiktok)"
        >
          <t2-svg
            class="mobile-menu--social-icon"
            icon-id="social-tiktok"
          />
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
import T2Svg from "@atoms/Svg/Svg.vue";
import T2Badge from "@atoms/Badge/Badge.vue";
import T2Button from "@atoms/Button/Button.vue";
import T2SelectDropdown from "@molecules/Dropdown/Dropdown.vue";
export default {
  components: {
    T2Svg,
    T2Badge,
    T2Button,
    T2SelectDropdown,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isMainActive: {
      type: Boolean,
      default: false,
    },
    isSubActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "link-click",
    "language-select",
    "event-click",
  ],
  methods: {
    handleButtonToggle() {
      this.$refs["drop-menu"].scrollTop = 0;
      this.$emit('link-click', this.data.default.submenu)
    },
    handleLinkToggle(link) {
      if (!link.event) {
        this.$refs["drop-menu"].scrollTop = 0;
      }

      this.$emit(this.getEvent(link.event), link);
    },
    getEvent(event) {
      return event ? "event-click" : "link-click";
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@import (reference) "~@/assets/less/heading.less";
@color-submenu-light-blue: #fcfcff;
.drop-menu {
  position: relative;

  &--item {
    width: 100%;
    background-color: @color-submenu-light-blue;
    left: 0;
    max-height: ~"calc(100vh - 85px)";
    overflow-y: scroll;
    position: fixed;
    top: 85px;
    padding-bottom: 70px;
    z-index: @zindex-dashboard-dropdown;
    transition: all 0.25s;
    transform: translate3d(0, -100%, 0);
    visibility: hidden;

    @media @tablet {
      height: 100vh;
    }
    @media @mobile {
      max-height: ~"calc(100vh - 60px)";
      top: 60px;
    }
    @media @mobile {
      transform: translate3d(100%, 0, 0);
    }
  }
  &--wrapper {
    margin: 0 auto;
    max-width: @grid-max-width;
  }
  &--login {
    padding: @grid-padding 0;
    max-width: 830px;
    margin: 0 auto;
    @media @tablet {
      padding: @grid-padding;
    }
    @media @mobile {
      padding: @grid-mobile-padding;
    }
  }
  &--title {
    .heading--2();
    text-align: center;
  }
  &--login-container {
    display: flex;
    justify-content: space-between;
    @media @mobile {
      flex-flow: column wrap;
      align-items: center;
    }
  }
  &--social-logins,
  &--login-form-wrapper {
    max-width: 318px;
    width: 100%;
  }
  &--social-logins {
    padding-top: 20px;
    @media @mobile {
      padding-top: 0;
    }
  }
  &--social-cta {
    padding-top: 15px;
    display: flex;
  }
  &--middle {
    max-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--middle-title {
    .heading--3();
    padding: 0 0 30px;
    @media @mobile {
      margin: 30px 0 20px;
      padding: 0;
    }
  }
  &--login-cta {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &--login-forgot {
    color: @color-dark;
    font-weight: 700;
    letter-spacing: 0.02em;
  }
  &--register {
    padding: @grid-padding 0;
    background-color: @color-light;
    min-height: 111px;
    text-align: center;
    position: relative;

    @media @mobile {
      margin-top: 20px;
      background-color: transparent;
    }
  }
  &--register-wrapper {
    @media @tablet {
      padding: 0 @grid-padding;
    }
    @media @mobile {
      padding: 0 @grid-mobile-padding;
    }
  }
  &--register-heading {
    .heading--3();
  }
  &--register-image {
    position: absolute;
    bottom: 0;
    margin-left: 50px;
    z-index: 3;
    @media @mobile {
      display: none;
    }
  }
  &--list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 10px;
    padding: 24px 0;
    border-bottom: 1px solid @color-lightest-gray;
  }
  &--list:last-child {
    border-bottom: unset;
  }
  &--list-item {
    padding: 2px @grid-padding;
    position: relative;
    width: 50%;
    @media @mobile {
      padding: 2px 0;
      width: 100%;
    }
  }
  &--list-item:first-child {
    @media @mobile {
      padding-top: 0;
    }
  }
  &--list-item:last-child {
    @media @mobile {
      padding-bottom: 0;
    }
  }
  &--list-item---red &--link {
    color: @color-red;
  }
  &--list-item---red &--link:hover {
    color: @color-red;
  }
  &--list-item---red &--icon,
  &--list-item---red &--link-image {
    fill: @color-red;
  }
  &--list-item---selected &--link {
    font-weight: bold;
    background-color: @color-grey-5;
  }
  &--link {
    color: @color-blue;
    display: block;
    padding: 0 30px 0 45px;
    position: relative;
    background-color: @color-light;
    border-radius: 6px;
    font-size: 14px;
    height: 42px;
    line-height: 42px;
    height: 50px;
    display: flex;
    align-items: center;
  }
  &--link:hover {
    background-color: @color-grey-5;
    color: @color-blue;
  }
  &--link-image {
    max-width: 24px;
    height: auto;
    max-height: 24px;
    margin-right: 10px;
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translate(0, -50%);
    fill: @color-blue;
  }
  &--icon {
    max-height: 24px;
    max-width: 24px;
    fill: @color-blue;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  &--overlay {
    background-color: transparent;
    cursor: zoom-out;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.25s ease-in-out;
    width: 100%;
    z-index: -1;
  }
  &--overlay---show {
    z-index: 11;
  }
  //modifiers
  &---active &--overlay {
    background-color: fade(@color-black, 80%);
    transition: all 0.25s ease-in-out;
    z-index: 11;
  }
  &---active &--item {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    z-index: @zindex-dashboard-dropdown---active;
  }
  &--dashboard-button {
    border-radius: 6px;
    border: 2px solid @color-blue;
    color: @color-blue;
    fill: @color-blue;
  }
  &--dashboard-button:hover {
    border: 2px solid @color-darkred;
  }
  &--dashboard-button:hover &--dashboard-icon {
    fill: @color-darkred;
  }
  &--dashboard-text {
    padding: 0 30px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: @color-blue;
    height: 40px;
    width: 100%;
  }

  &--dashboard-icon {
    width: 16px;
    height: 16px;
    margin-right: 15px;
    fill: @color-blue
  }
}

.mobile-menu {
  background-color: @color-submenu-light-blue;
  height: 100%;
  left: 0;
  max-height: ~"calc(100vh - 85px)";
  overflow-y: auto;
  position: fixed;
  top: 85px;
  transform: translate3d(0, -100%, 0);
  transition: all 0.25s;
  visibility: hidden;
  width: 100vw;
  z-index: @zindex-main-dropdown;

  @media @mobile {
    max-height: ~"calc(100vh - 60px)";
    top: 60px;
  }
  @media @mobile {
    transform: translate3d(-100%, 0, 0);
  }

  &---open {
    transition: all 0.25s;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    z-index: @zindex-main-dropdown---active;

    @media @mobile {
      transform: translate3d(0, 0, 0);
    }
  }

  &--container {
    padding: 0 @grid-mobile-padding;
  }
  &--list {
    list-style: none;
    margin: 0;
    padding: 5px 0 0 0;
  }
  &--item {
    border-bottom: 1px solid @color-darker-light;
    height: 52px;
    line-height: 52px;
  }
  &--item---button {
    height: unset;
  }
  &--item-button-container {
    line-height: unset;
    height: unset;
    width: 50%;
    padding: 16px 20px 16px 0;

    @media @mobile {
      width: 100%;
      padding: 16px 0;
    }
  }
  &--item-button {
    width: 100%;
    font-size: 16px;
    line-height: 24px;

    @media @mobile {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &--item-button.v-button--secondary:hover,
  &--item-button.v-button--secondary:focus {
    color: @color-white;
  }
  &--item-button.v-button--secondary:hover {
    background: @color-red;
  }
  &--link {
    position: relative;
    display: block;
    color: @color-dark;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.02em;
  }
  &--cta {
    display: inline-flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 30px;
    font-size: 12px;
    text-transform: uppercase;
    color: @color-white;
    font-weight: 700;
    border-radius: 6px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
    position: relative;
  }
  &--cta:hover {
    color: @color-white;
  }
  &--cta-arrow-btn {
    width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid @color-white;
  }
  &--cta-arrow {
    width: 6px;
    height: 6px;
    margin-right: 5px;
    border-top: 1.5px solid @color-white;
    border-right: 1.5px solid @color-white;
    transform: rotate(45deg);
  }

  &--icon {
    bottom: 0;
    fill: @color-dark;
    height: 9px;
    margin: auto 0;
    position: absolute;
    right: 0;
    top: -2px;
    width: 6px;
  }

  &--social {
    padding: @grid-padding 0;
    text-align: center;
  }

  &--social-link {
    background-color: @color-darkestlight;
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    margin: 0 @grid-mobile-padding;
    position: relative;
    width: 30px;
  }
  &--social-link:hover {
    background: @color-blue;
  }
  &--social-link:hover &--social-icon {
    fill: @color-darkestlight;
  }
  &--social-icon {
    bottom: 0;
    fill: @color-blue;
    height: 14px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    vertical-align: middle;
    width: 14px;
  }
  &--item---no-border {
    border-bottom: 0;
  }
  &--cta---account {
    margin-top: 10px;
    background-color: @color-blue;
  }
  &--cta---account:hover {
    background-color: @color-darkblue;
  }
  &--cta---topup {
    border: 2px solid @color-red;
    color: @color-red;
  }
  &--cta---topup:hover {
    border-color: @color-darkred;
    color: @color-darkred;
  }
  &--language-select-wrapper {
    position: relative;
    width: 100%;
    padding: 32px 16px 48px 16px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
