<template>
  <div class="dashboard">
    <div class="dashboard-container">
      <div class="dashboard-wrapper">
        <component
          :is="refillCampaignBanner.component"
          v-if="refillBannerLoaded"
          :data="refillCampaignBanner.componentData"
          @close-click="refillBannerLoaded = false"
          @win-prize-click="handleGameRedirect"
          @receive-prize-click="handleCampaignDeliveryDetailClick"
        />
        <t2-delivery-details-modal
          v-if="deliveryLoaded"
          :data="deliveryData"
          @submit="handleRefillFormSubmit"
        />
        <t2-points-banner
          v-if="awardsCampaignBannerData"
          class="awards-campaign-banner"
          :data="awardsCampaignBannerData"
          @cta-click="handleAwardsCampaignBannerClick"
          @close-click="handleAwardsCampaignBannerCloseClick"
        />
        <t2-banner
          v-if="showCampaignBanner"
          :data="campaignBannerData"
          @start-click="handleCampaignBannerClick"
          @close-click="handleCampaignCloseButtonClick"
        />
        <t2-1000-samsung-banner
          v-if="awardsCampaignPrizeBannerData"
          :data="awardsCampaignPrizeBannerData"
          @button-click="handleAwardsCampaignPrizeBannerClick"
          @close-click="awardsCampaignPrizeBannerData = null"
        />
        <t2-navigation @open-polar-modal="openPolarModal" />
        <live-chat-widget
          v-if="liveChat.email !== undefined && userMsisdn"
          :license="liveChat.license"
          :customer-name="userMsisdn"
          :customer-email="liveChat.email"
          :session-variables="sessionVariables"
          :visibility="liveChat.visibility"
          :group="liveChat.group"
          :chat-between-groups="liveChat.chatBetweenGroups"
          @visibility-changed="handleVisibilityChange"
        />
        <div class="user-services">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LiveChatWidget } from "@livechat/widget-vue";
import T2Navigation from "@/components/navigation/index.vue";
import T2PointsBanner from "@organisms/Campaigns/SummerCampaign2023/Banner/Banner.vue";
import T2Banner from "@organisms/Campaigns/Christmas2022/Banner/Banner.vue";
import T21000SamsungBanner from "@organisms/Campaigns/1000Samsung/Banner/Banner.vue";
import T2DeliveryDetailsModal from "@organisms/Campaigns/FonosCampaign/PrizeReceiveModal/Modal.vue";
import T2RefillCampaignGameBanner from "@organisms/Campaigns/FonosCampaign/GameBanner/GameBanner.vue";
import T2RefillCampaignWinBanner from "@organisms/Campaigns/FonosCampaign/PrizeWonBanner/PrizeWonBanner.vue";
import UserService from "@/services/user.js";
import PolarModalService from "@/services/polarModal.js";
import CampaignService from "@/services/campaign.js";
import SummerCampaignService from "@/services/summerCampaign2023.js";
import CampaignApi from "@/api/campaign.js";
import AwardsCampaignApi from "@/api/awardsCampaign.js";
import MainLayoutService from "./services/mainLayout.js";
import RefillCampaignService from "./services/refillCampaign.js";
import RefillCampaignGameService from "@/views/zzz-refill-campaign-game/services/index.js";
import RefillCampaignGameApi from "@/views/zzz-refill-campaign-game/api/index.js";
import RefillCampaignGameConstants from "@/views/zzz-refill-campaign-game/constants.js";
import refillCampaignSuccessConstants from "@/views/zzz-refill-campaign-success/constants.js";
import constants from "@/constants/index.js";
// import AwardsCampaignDataService from "@/views/awards-campaign-info/services/index.js";
// import localConstants from "@/views/summer-campaign-2023-purchase/constants";
// import GameService from "@/views/summer-campaign-2023-game/services";

// NOTE: All of the code is commented due to no active campaign currently
// and commented code should be refactored to a more universal solution in the future (TODO)
export default {
  name: "MainLayout",
  components: {
    T2Navigation,
    T2PointsBanner,
    T2Banner,
    T21000SamsungBanner,
    T2DeliveryDetailsModal,
    LiveChatWidget,
    T2RefillCampaignGameBanner,
    T2RefillCampaignWinBanner,
  },
  inject: ["emitter"],
  emits: ["open-polar-modal"],
  data() {
    return {
      campaignBannerData: null,
      showNavigation: true,
      showCampaignBanner: false,
      campaignTransactionData: null,
      awardsCampaignBannerData: null,
      awardsCampaignPrizeBannerData: null,
      showDeliveryForm: false,
      liveChat: {
        license: constants.LiveChatLicense,
        email: null,
        group: "2",
        visibility: "minimized",
        chatBetweenGroups: false,
      },
      deliveryLoaded: false,
      deliveryData: RefillCampaignGameService.formatPhysicalPrizeForm([], false),
      winnings: null,
      sessionVariables: {},
      refillBannerLoaded: false,
      refillCampaignBanner: null,
      locations: null,
    };
  },
  computed: {
    userMsisdn() {
      return this.$store.getters["user/getCurrentMsisdn"];
    }
  },
  created() {
    return Promise.all(this.formatRequests())
  },
  methods: {
    openPolarModal(data) {
      this.$emit("open-polar-modal", data);
    },
    formatRequests() {
      const promises = [
        this.loadUserProfile(),
        this.loadCreditCardInformationBanner(),
      ];
      
      if (SummerCampaignService.isCampaignActive()) {
        promises.push(
          this.loadAwardsCampaignPrizeBanner()
            .then(this.loadAwardsCampaignPointsBanner)
        );
      }

      if (CampaignService.showCampaign(constants.AvailableCampaigns.Refill)) {
        promises.push(this.loadRefillCampaign())
      }

      return promises;
    },
    loadUserProfile() {
      return this.$store.dispatch("user/loadUserInfo")
        .then(({ data }) => {
          this.liveChat.email = data.email || null;
        })
        .catch(() => {
          this.liveChat.email = null;
        });
    },
    handleVisibilityChange({ visibility }) {
      if (visibility !== "maximized") {
        return;
      }

      return UserService.getLiveChatToken()
        .then(({ data }) => {
          this.sessionVariables = {
            token: data.token,
            msisdn: this.$store.getters["user/getCurrentMsisdn"],
          };
        })
        .catch((response) => PolarModalService.handleErrorResponse(this, response));
    },
    loadCreditCardInformationBanner() {
      return this.$store.dispatch("payments/fetchCardDetails")
        .then(({ data }) => {
          const informationBanner = MainLayoutService.formatInformationBanner(data.expirationDate, data.status);

          if (informationBanner) {
            this.emitter.emit(constants.Modals.AddPopUp, informationBanner);
          }
        })
        .catch(() => {});
    },

    // Campaign logic
    handleGameRedirect() {
      return this.$router.push({
        path: constants.Routes.RefillCampaignGame,
        query: { transactionId: this.campaignTransactionData.transactionId }
      });
    },
    handleCampaignDeliveryDetailClick() {
      if (this.locations) {
        this.$nextTick(() => {
          this.emitter.emit(constants.ModalControl.Open, {
            id: constants.Modals.FonosPrizeReceive
          });
        });

        return;
      }

      this.refillCampaignBanner.componentData.buttonLoader = true;
      this.deliveryLoaded = true;

      return CampaignApi.getLocations().then(({ data }) => {
        this.locations = data;
        this.deliveryData = RefillCampaignGameService.formatPhysicalPrizeForm(
          this.locations,
          this.campaignTransactionData.typeId === RefillCampaignGameConstants.PrizeTypes.PhysicalSpecial
        );

        this.$nextTick(() => {
          this.emitter.emit(constants.ModalControl.Open, {
            id: constants.Modals.FonosPrizeReceive
          });
        });
      })
      .catch((response) => PolarModalService.handleErrorResponse(this, response))
      .finally(() => { this.refillCampaignBanner.componentData.buttonLoader = false });
    },
    loadRefillCampaign() {
      return RefillCampaignGameApi.getValidTransactions()
        .then(({ data }) => {
          if (data.length === 0) {
            return Promise.resolve();
          }

          this.campaignTransactionData = data[0];
          this.refillCampaignBanner = this.campaignTransactionData.prizeId
            ? RefillCampaignService.formatWinBanner(this.campaignTransactionData.name)
            : RefillCampaignService.formatGameBanner();
          this.refillBannerLoaded = true;
        })
        .catch(response => PolarModalService.handleErrorResponse(this, response));
    },
    handleRefillFormSubmit(data) {
      const deliveryData = this.campaignTransactionData.typeId === RefillCampaignGameConstants.PrizeTypes.PhysicalSpecial
        ? RefillCampaignGameService.formatPhoneDeliveryModel(data)
        : RefillCampaignGameService.formatDeliveryModel(data);
      this.deliveryData.button.loader = true;

      return RefillCampaignGameApi.save(this.campaignTransactionData.transactionId, deliveryData)
        .then(() => {
          this.emitter.emit(constants.ModalControl.Close, {
            id: constants.Modals.FonosPrizeReceive
          });
          this.deliveryLoaded = false;

          return this.$router.push({
            name: constants.Routes.RefillCampaignSuccess.Name,
            params: { status: refillCampaignSuccessConstants.RouteStates.PhysicalPrize },
          });
        })
        .catch(response => PolarModalService.handleErrorResponse(this, response))
        .finally(() => { this.deliveryData.button.loader = false });
    },

    handleCampaignCloseButtonClick() {
      this.showCampaignBanner = false;

      if (!this.campaignBannerData.isSpecial) {
        localStorage.setItem(constants.LocalStorage.HideCampaignBanner, true);
      }
    },
    loadAwardsCampaignPointsBanner() {
      this.awardsCampaignBannerData = {
        title: constants.Resources.SummerCampaign2023.BannerTitle,
        image: constants.Images.SummerCampaign2023.Banner.Image,
        button: this.winnings.length > 0
          ? constants.Resources.SummerCampaign2023.BannerDeliveryFormButtonText
          : constants.Resources.SummerCampaign2023.BannerRedirectButtonText,
        iconId: constants.Icons.CloseBold,
        points: 0,
        description: this.winnings.length > 0
          ? (constants.Resources.SummerCampaign2023.BannerDeliveryFormDescription).replace("{0}", this.winnings[0].prizeName)
          : constants.Resources.SummerCampaign2023.BannerRedirectDescription,
        loader: true,
      }
      return this.$store
        .dispatch("awardsCampaign/loadPoints")
        .then(() => {
          this.awardsCampaignBannerData.points =
            this.$store.getters["awardsCampaign/getPoints"];
        })
        .finally(() => {
          this.awardsCampaignBannerData.loader = false;
        });
    },
    loadAwardsCampaignPrizeBanner() {
      return this.$store
        .dispatch("awardsCampaign/loadWinnings")
        .then(() => {
          this.winnings = this.$store.getters["awardsCampaign/getWinnings"];
          // if (this.winnings.length > 0) {
          //   this.awardsCampaignPrizeBannerData =
          //     AwardsCampaignDataService.formatPrizeBanner(this.winnings[0]);
          // }
        })
        .catch(() => {
          // Do nothing
        });
    },
    handleAwardsCampaignBannerClick() {
      if (this.winnings.length > 0) {
        // this.deliveryData = GameService.getDeliveryData();
        // this.goToStep(localConstants.Steps.DeliveryDetails);
        this.deliveryLoaded = true;

        this.$nextTick(() => {
          this.emitter.emit("open-modal", {
            id: "summer-campaign-delivery-modal",
          });
        });
        return;
      }

      return this.$router.push(constants.Routes.SummerCampaign2023);
    },
    handleAwardsCampaignBannerCloseClick(){
      this.awardsCampaignBannerData = null;
    },
    loadCampaignBanner() {
      return CampaignApi.getValidTransaction()
        .then((response) => {
          this.campaignTransactionData = response.data;

          if (
            !this.campaignTransactionData.isSpecial &&
            localStorage.getItem(constants.LocalStorage.HideCampaignBanner)
          ) {
            return;
          }

          this.campaignBannerData = CampaignService.formatCampaignBanner(
            this.campaignTransactionData.isSpecial
          );
          this.showCampaignBanner = true;
        })
        .catch(() => {
          // Do nothing
        });
    },
    handleCampaignBannerClick() {
      return this.$router.push({
        name: constants.Routes.Campaign.Name,
        params: {
          transactionId: this.campaignTransactionData.transactionId,
        },
      });
    },
    handleAwardsCampaignPrizeBannerClick() {
      return this.$router.push(
        constants.Routes.AwardsCampaignDelivery.replace(
          ":winning",
          this.$store.getters["awardsCampaign/getWinnings"][0].winningId
        )
      );
    },
    handleDeliveryDetailsClick({ firstName, lastName, email, terminal }) {
      // const deliveryPage = this.deliveryData.content[localConstants.Steps.DeliveryDetails.Component];
      // deliveryPage.button.loader = true;
      this.deliveryForm = {
        fullName: `${firstName} ${lastName}`,
        email,
        postCode: terminal.value.zip,
        deliveryLocation: `${terminal.value.name}, ${terminal.value.address}`,
        winningId: this.winnings[0].winningId
      };
      return AwardsCampaignApi.saveShipment(this.deliveryForm)
        .then(({ data: isNotValid }) => {
          if (isNotValid) {
            // const page = this.deliveryData.content[localConstants.Steps.EmailConfirmation.Component];
            // page.description = page.descriptionTemplate.replace("{0}", email);
            // this.deliveryData.component = localConstants.Steps.EmailConfirmation.Component;
            return;
          } else {
            this.$store.commit("awardsCampaign/setPrize", this.winnings[0].prizeName);
            return this.$router.push({
              name: constants.Routes.SummerCampaign2023SuccessStatus.Name,
              params: {
                status: "sekme",
              },
            });
          }
        })
        .catch((response) => {
          PolarModalService.handleErrorResponse(this, response);
        })
        .finally(() => {
          // deliveryPage.button.loader = false;
        });
    },
    handleCodeSubmit(code) {
      // const page = this.deliveryData.content[localConstants.Steps.EmailConfirmation.Component];
      // page.button.loader = true;
      return AwardsCampaignApi.confirmEmail(parseInt(code), this.deliveryForm.email)
        .then(() => {
          this.$store.commit("awardsCampaign/setPrize", this.winnings[0].prizeName);
          return this.$router.push({
            name: constants.Routes.SummerCampaign2023SuccessStatus.Name,
            params: {
              status: "sekme",
            },
        });
        })
        .catch((response) => {
          PolarModalService.handleErrorResponse(this, response);
        })
        .finally(() => {
          // page.button.loader = false;
        });
    },
    handleCancelClick() {
      // this.deliveryData.component = localConstants.Steps.DeliveryDetails.Component;
    },
    goToStep(step) {
      window.scrollTo(0, 0);
      this.deliveryData.component = step.Component;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/less/main.less";

.newsletter--label {
  margin-top: 1rem;
}

.main {
  padding-top: 85px;
  overflow: visible;
  height: auto;

  @media @mobile {
    padding-top: 60px;
  }

  &---no-padding {
    padding-top: 0 !important;
  }
}

.dashboard {
  position: relative;

  @media @tablet {
    overflow: hidden;
  }

  &-wrapper {
    display: flex;
    width: 100%;
    position: relative;

    @media @laptop {
      margin-bottom: 72px;
      flex-direction: column;
    }
    @media @tablet {
      margin: 0 -10px 72px;
      width: 100vw;
    }
  }

  &-container {
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    transition: padding .3s ease-in;

    @media screen and (max-width: @grid-max-width) {
      padding: 0 @grid-padding 0 0;
    }

    @media @laptop {
      padding: 0;
    }
    @media @tablet {
      flex-wrap: wrap;
      padding: 0 10px;
    }
    @media @mobile {
      padding: 0 @grid-mobile-padding;
    }
  }
  .awards-campaign-banner {
    z-index: 10;
    @media @tablet {
      position: relative;
    }
  }
}
</style>
